<template>
  <div class="row">
    <div class="col">
      <div class="page-description text-center">
        <h1>{{ $t("claims.new.create") }}</h1>
        <span>{{ $t("claims.new.description") }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="row">
        <!-- Create Drive Off  -->
        <div class="col-12 col-xl-4 col-lg-6 col-md-6">
          <div class="card pricing-basic pricing-selected" id="newclaim">
            <div class="card-body">
              <h3 class="plan-title">
                {{ $t("claims.new.driveoff.title")
                }}
                <!-- <span class="badge badge-success badge-style-light">Free</span
                ><span class="badge badge-info badge-style-light m-r-sm"
                  >Popular</span
                > -->
              </h3>
              <span class="plan-description">{{
                $t("claims.new.driveoff.description")
              }}</span>
              <span class="plan-description">{{
                $t("claims.new.requirements", { text: "Drive Off" })
              }}</span>
              <ul class="plan-list">
                <li>{{ $t("claims.new.vehicleRegistration") }}</li>
                <li>{{ $t("claims.new.fuelValue") }}</li>
                <li>{{ $t("claims.new.litres_of_fuel_dispensed") }}</li>
                <li>{{ $t("claims.new.pumpNumber") }}</li>
                <li>{{ $t("claims.new.tillReceipt") }}</li>
                <li>{{ $t("claims.new.vehicleImage") }}</li>
                <li>
                  ({{ $t("global.optional").toUpperCase() }})
                  {{ $t("claims.new.cctv") }}
                </li>
                <li class="notick"></li>
              </ul>
              <div class="m-t-md new-claim-btn">
                <button
                  class="btn btn-primary btn-lg"
                  @click="
                    $router.push('/claims/new/driveoff');
                    selectTab('driveoff');
                  "
                  type="button"
                >
                  {{ $t("claims.new.createNow") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Create NMOP -->
        <div class="col-12 col-xl-4 col-lg-6 col-md-6">
          <div class="card pricing-basic" id="newclaim">
            <div class="card-body">
              <h3 class="plan-title">
                {{ $t("claims.new.nmop.title")
                }}
                <!-- <span class="badge badge-success badge-style-light"
                  >Free</span
                > -->
              </h3>
              <span class="plan-description"
                >{{ $t("claims.new.nmop.description") }}
              </span>
              <span class="plan-description">{{
                $t("claims.new.requirements", { text: "No Means of Payment" })
              }}</span>
              <!-- Extra space to make the cards even size | Make responsive -->
              <!-- <p class="nmop-responsive"></p>  -->
              <ul class="plan-list">
                <li>{{ $t("claims.new.nameAndAddress") }}</li>
                <li>{{ $t("claims.new.vehicleRegistration") }}</li>
                <li>{{ $t("claims.new.fuelValue") }}</li>
                <li>{{ $t("claims.new.litres_of_fuel_dispensed") }}</li>
                <li>{{ $t("claims.new.pumpNumber") }}</li>
                <li>{{ $t("claims.new.tillReceipt") }}</li>
                <li>{{ $t("claims.new.vehicleImage") }}</li>
                <li>
                  ({{ $t("global.optional").toUpperCase() }})
                  {{ $t("claims.new.cctv") }}
                </li>
              </ul>
              <div class="m-t-md new-claim-btn">
                <button
                  class="btn btn-primary btn-lg"
                  @click="
                    $router.push('/claims/new/nmop');
                    selectTab('nmop');
                  "
                  type="button"
                >
                  {{ $t("claims.new.createNow") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Create CNAF -->
        <div class="col-12 col-xl-4 col-lg-6 col-md-6">
          <div class="card pricing-basic">
            <div class="card-body">
              <h3 class="plan-title">
                Customer Not at Fault
                <span
                  v-if="!showCnaf"
                  class="badge badge-info badge-style-light card-abandoned"
                >
                  {{ $t("global.comingsoon").toUpperCase() }}
                </span>
                <span class="badge badge-warning badge-style-light"
                  >£15</span
                >
              </h3>
              <span class="plan-description"
                >A customer has dispensed fuel and through no fault of their own
                was unable to make payment at the time.
              </span>
              <span class="plan-description">{{
                $t("claims.new.requirements", { text: "Customer Not at Fault" })
              }}</span>
              <!-- Extra space to make the cards even size | Make responsive -->
              <!-- <p class="nmop-responsive"></p>  -->
              <ul class="plan-list">
                <li>{{ $t("claims.new.vehicleRegistration") }}</li>
                <li>{{ $t("claims.new.fuelValue") }}</li>
                <li>{{ $t("claims.new.litres_of_fuel_dispensed") }}</li>
                <li>{{ $t("claims.new.pumpNumber") }}</li>
                <li>{{ $t("claims.new.tillReceipt") }}</li>
                <li>{{ $t("claims.new.vehicleImage") }}</li>
                <li>
                  ({{ $t("global.optional").toUpperCase() }})
                  {{ $t("claims.new.cctv") }}
                </li>
                <li class="notick"></li>
              </ul>
              <div class="m-t-md new-claim-btn">
                <button
                  class="btn btn-primary btn-lg"
                  :class="{ disabled: !showCnaf }"
                  type="button"
                  @click="$router.push('/claims/new/cnaf')"
                  :disabled="!showCnaf"
                >
                  {{ $t("claims.new.createNow") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../../services/auth-header";

export default {
  name: "NewClaim",
  data() {
    return {
      showCnaf: false,
    };
  },
  mounted() {
    this.checkCnaf();
  },
  methods: {
    checkCnaf() {
      axios
        .get(
          `https://api.varsanpr.com/api/clients/${this.$store.state.auth.user.selectedClient}/cnaf`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.data.cnaf == true) {
            this.showCnaf = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.notick::before {
  content: none !important;
}

.app-content .page-description {
  border: none;
}

.new-claim-btn {
  text-align: center;
}

.badge.badge-style-light.badge-info.card-abandoned {
  color: #f5ba22;
  background-color: rgba(245, 186, 34, 0.25) !important;
}

@media (min-width: 1440px) {
  .nmop-responsive {
    padding: 11px;
    margin: 0;
  }
}
</style>
